/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import Carousel from "../atoms/carousel";
import HomePageRow from "../section-templates/home-page-row";
import { sendNonStoryPageView } from "../helper/helper";

export const HomePage = ({ data }) => {
  React.useEffect(() => {
    sendNonStoryPageView();
  }, []);

  const renderDataDiv = data.collection.items.map((item, index) => {
    const templateType = item["associated-metadata"].layout;
    if (!templateType) return null;
    if (item.items.length === 0) return null;

    const stories = React.useMemo(
      () =>
        item.items.map((story) => {
          return story.story;
        }),
      []
    );

    switch (templateType) {
      case "FiveStorySlider": {
        const premiumStories = stories.filter((story) => story.access === "subscription").slice(0, 4);
        const freeReads = stories.find((story) => story.access !== "subscription");
        premiumStories.push(freeReads);
        const finalStories = premiumStories;
        return <Carousel key={item.id} stories={finalStories} />;
      }
      default: {
        return <HomePageRow key={item.id} headerName={item.name} slug={item.slug} stories={stories} home={true} />;
      }
    }
  });
  return (
    <>
      <div className="body-inner-container" style={{ gap: "20px" }}>
        {renderDataDiv}
      </div>
    </>
  );
};
